import React from "react";
import { useRouter } from "next/router";

import Avatar from "atoms/Avatar";
import IconProfileViews from "atoms/SvgIcons/IconProfileViews.svg";
import Text from "atoms/Text";
import IconStar from "atoms/SvgIcons/IconStar.svg";
import IconLocationGrey from "atoms/SvgIcons/IconLocationGrey.svg";
import IconCash from "atoms/SvgIcons/IconCash.svg";
import Button from "atoms/Button";
import LinkAnchor from "atoms/LinkAnchor";
import Share from "molecules/Share";
import ClinicServices from "molecules/ClinicServices";
import { ClinicsDoctors, ClinicTimings } from "./ClinicSummary";

import { getDynamicUrl, getDynamicUrlV1 } from "services/dynamic-url.service";


const ClinicProfileDataSection = ({
    className = "",
    screenSize,
    clinicProfileData,
    forDetails = false,
    setAlertMessage,
    isShowVerifiedIcon,
    isAyurvedaPage = false,
    isClinicListing,
    ...props
}) => {
    const router = useRouter();

    return (
        <>
            <div id="clinicProfileSection" className={`${className} flex gap-3 lg:gap-4 ${isClinicListing ? 'cursor-pointer' : ''}`} onClick={isClinicListing ? (e) => {e.stopPropagation(); router?.push(clinicProfileData?.href)} : null}>
                <ClinicProfilePicture
                    src={clinicProfileData?.preview_image ? process.env.NEXT_PUBLIC_PHP_BUCKET_URL + clinicProfileData?.preview_image : '/images/default-preview-image.svg'}
                    numberOfViews={clinicProfileData?.views}
                    href={clinicProfileData?.href}
                    isShowVerifiedIcon
                />
                <ClinicProfileData
                    clinicName={clinicProfileData?.clinic_name}
                    rating={clinicProfileData?.rating}
                    clinicAddress={clinicProfileData?.location}
                    fees={clinicProfileData?.fee}
                    clinicServices={clinicProfileData?.servicesList}
                    clinicTimings={clinicProfileData?.timingsList}
                    screenSize={screenSize}
                    href={clinicProfileData?.href}
                    about={clinicProfileData?.about}
                    clinicsDoctors={clinicProfileData?.clinicsDoctors}
                    forDetails={forDetails}
                    setAlertMessage={setAlertMessage}
                    isAyurvedaPage={isAyurvedaPage}
                    bookingEnabled={clinicProfileData.bookingEnabled}
                    ratingCount={clinicProfileData?.ratingCount}
                />
            </div>
            {screenSize < 768 ? <div className="border w-82 mt-2.5 mb-2" /> : null}
        </>
    );
};

const ClinicProfilePicture = ({
    src,
    numberOfViews = 0,
    href,
    isShowVerifiedIcon = false,
    ...props
}) => {
    return (
        <div id="clinicDisplayPicture" className="flex flex-col gap-2.5 items-center">
            <LinkAnchor href={href} className='group relative' dataAutomation='navigate-to-clinic-detail' >
                <Avatar
                    className={`w-30 h-30 rounded-full border-gray-200 border`}
                    rounded="full"
                    src={src}
                />
            </LinkAnchor>
            {numberOfViews ?
                <ClinicProfileViews className="" numberOfViews={numberOfViews} />
                : null}
        </div>
    );
};

const ClinicProfileViews = ({
    className = "",
    numberOfViews,
    ...props
}) => {
    return (
        numberOfViews ?
            <div id="clinicViews" className={`flex flex-row justify-between items-center w-19 ${className}`}>
                <IconProfileViews className={`w-3.5 h-3.5`} />
                <Text className={`text-xs text-gray-600`}>{numberOfViews} views</Text>
            </div>
            : null
    );
};

const ClinicProfileData = ({
    clinicName,
    rating,
    clinicAddress,
    fees,
    clinicServices = [],
    clinicTimings = "",
    clinicsDoctors = [],
    href,
    about = "",
    forDetails = false,
    setAlertMessage,
    isAyurvedaPage,
    bookingEnabled,
    ratingCount,
    ...props
}) => {
    const router = useRouter();

    const shareProfile = async (event) => {
        event.preventDefault();
        const response = await getDynamicUrlV1({
            parameters: router?.asPath,
            title: clinicName,
            description: "",
            dynamicLinkType: "clinic_profile_link",
        });
        if (response?.status) {
            if (response?.data?.status) {
                const dynamicUrl = response.data.entity;
                navigator.clipboard.writeText(dynamicUrl);
            } else {
                setAlertMessage({
                    type: "error",
                    message: response?.data?.errorType == "validation" ? response?.data?.message?.[0] : response?.data?.message,
                })
            }
        }
    };

    return (
        (<div className="flex flex-col gap-2 w-full overflow-visible">
            <div className={`flex flex-col ${isAyurvedaPage ? "" : "md:flex-row md:justify-between md:items-center"} gap-1`}>
                {forDetails == true ?
                    <Text id="clinicName" className={`text-lg font-semibold text-gray-900`}>
                        {clinicName}
                    </Text> :


                        <Text id="clinicName" className={`text-lg text-gray-900 font-semibold`}>
                            {clinicName}
                        </Text>
                }

                <div className="flex gap-3 items-center">
                    <div id="clinicRating" className="flex gap-1.5">
                        <IconStar className="text-other-yellow-650 w-4 h-4" />
                        <Text className="col-end-7 col-span-2 text-14-16 font-Open-Sans font-normal text-other-blue-350">{((!rating || rating < 1)) ? "New" : (rating) + "/5"}<span className="text-gray-600 text-sm font-normal">{ratingCount ? ` (${ratingCount} Patient ${ratingCount === 1 ? 'Story' : 'Stories'})` : ''}</span></Text>
                    </div>
                    {forDetails ?
                        <Share
                            className="w-7 h-7 !bg-gray-50 !shadow-none rounded-full"
                            type='ONLY_ICON'
                            onClick={shareProfile}
                        />
                        : null}
                </div>
            </div>
            {clinicAddress ? <div id="clinicAddress" className="flex gap-1.5">
                <IconLocationGrey className="w-4 h-4 mt-0.75" />
                <Text className={`text-sm line-clamp-2`}>
                    {clinicAddress}
                </Text>
            </div> : null}
            {fees ? <div id="clinicFees" className="flex gap-3">
                <Text className={`text-sm text-gray-900`}>
                    Consultation fee
                </Text>
                <div className="flex items-start gap-1.5">
                    <IconCash className="w-4 h-4" />
                    <Text className={`text-sm text-other-blue-350 font-semibold`}>
                        {fees}
                    </Text>
                </div>
            </div> : null}
            {props?.screenSize >= 768 ?
                <div className="flex flex-col gap-3.5">
                    <ClinicServices
                        className=""
                        services={clinicServices}
                    />
                    {clinicTimings?.length ?
                        <ClinicTimings
                            className="mb-0 lg:mb-3.5"
                            timings={clinicTimings}
                        />
                        : null}

                    {forDetails == false ?
                        (props?.screenSize > 820)
                            ?
                        <div className="flex flex-row gap-1 items-end">
                            <ClinicsDoctors
                                className="flex"
                                isShowArrow={clinicsDoctors?.length > 2}
                                slideClass="!w-56 !h-29.5"
                                doctorsList={clinicsDoctors}
                                navElement={clinicName.slice(0, 5).replace(/\s/g, '')}
                            />
                            {bookingEnabled==1 &&
                                <Button
                                    className={`
                                        bg-primary1-500
                                        rounded-sm-0.5
                                        px-1
                                        py-1
                                        ml-auto
                                        mb-14
                                        w-[192px]
                                        h-[36px]
                                        mr-5
                                    `}
                                    variant="buttonSize--extraSmall_v3"
                                    size="extraSmall_v3"
                                    onClick={() => router.push(href)}
                                >
                                    Book Clinic Visit
                                </Button>
                            }
                        </div>
                            :
                        <ClinicsDoctors
                            className="flex mb-4"
                            isShowArrow={clinicsDoctors?.length > 2}
                            slideClass="!w-56 !h-29.5"
                            doctorsList={clinicsDoctors}
                            navElement={clinicName.slice(0, 5).replace(/\s/g, '')}
                        />
                        
                    : null}
                </div>
                : null}
        </div>)
    );
};


export default ClinicProfileDataSection;