import React, { useState } from "react";
import { useRouter } from "next/router";

import Card from "atoms/Card";
import Text from "atoms/Text";
import Carousel from "atoms/Carousel";
import IconArrowPrevious from "atoms/SvgIcons/IconArrowPrevious.svg"
import IconArrowNext from "atoms/SvgIcons/IconArrowNext.svg"
import Button from "atoms/Button";

import ClinicServices from "molecules/ClinicServices";
import ClinicsDoctorCard from "molecules/ClinicsDoctorCard";

import ClinicProfileDataSection from "organisms/ClinicProfileDataSection";
import Alert from "molecules/Alert";
import { getAuth } from 'services/identity.service';
import LinkAnchor from "atoms/LinkAnchor";
import useWindowSize from 'customHook/useWindowSize';
const auth = getAuth();
const isLoggedIn = (auth?.user?.userType === 1 || auth?.user?.userType === undefined);



const DoctorSmallCardSlider = ({
    className = "",
    isShowArrow = true,
    slideClass = "",
    doctorsList = [],
    isAyurvedaPage = false,
    navElement,
    heading,
    headingClass = '',
    handleViewMoreFeaturedDoctors,
    ...props
}) => {
    return doctorsList?.length ? (
        <div className={`${className} w-full relative`}>
            <div className="flex flex-row justify-between items-start mb-[6px] px-3 md:px-0 mt-1 ">
                {heading && <p className={`${headingClass}  text-sm font-semibold`}>{heading}</p>}
                <Button
                    variant="Link"
                    onClick={handleViewMoreFeaturedDoctors} dataAutomation='blog-section-homepage' className='text-primary1-500 hover:underline font-semibold text-12-16  md:mr-0'>
                    <span className='whitespace-nowrap'>View All</span>
                </Button>
            </div>




            {isShowArrow ?
                <div className="w-full hidden md:block ">
                    <button
                        className={`${navElement}Arrow--prev absolute -left-4 top-20 translate-x-2 z-2 group`}
                    >
                        <IconArrowPrevious
                            className={`w-4.5 h-4.5 p-1 text-center border-1.4 rounded-full bg-basic-white border-primary1-500 text-primary1-900`}
                            color="#24ACDA"
                            strokeWidth="10"
                        />
                    </button>
                    <button
                        className={`${navElement}Arrow--next absolute -right-4 top-20 -translate-x-2 z-2 group`}
                    >
                        <IconArrowNext
                            className={`w-4.5 h-4.5 p-1 text-center border-1.4 rounded-full bg-basic-white border-primary1-500 text-primary1-900`}
                            color="#24ACDA"
                            strokeWidth="10"
                        />
                    </button>
                </div>
                : null}
            <div type="static" className="w-full mr-5 mt-0 md:p-0 pl-4">
                <Carousel
                    breakpoints={{
                        0: {
                            slidesPerView: 1.1,
                            slidesPerGroup: 1,
                        },
                        320: {
                            slidesPerView: 1.4,
                            slidesPerGroup: 1,
                        },
                        360: {
                            slidesPerView: 1.6,
                            slidesPerGroup: 1,
                        },
                        390: {
                            slidesPerView: 1.7,
                            slidesPerGroup: 1,
                        },
                        500:{
                            slidesPerView: 2.1,
                            slidesPerGroup: 1,
                        },
                        650:{
                            slidesPerView: 2.8,
                            slidesPerGroup: 1,
                        },
                        768: {
                            slidesPerView: 3.3,
                            slidesPerGroup: 1,
                        },
                        1024: {
                            slidesPerView: 5.5,
                            slidesPerGroup: 1,
                        },
                    }}
                    centeredSlides={false}
                    isShowArrow={isShowArrow}
                    spaceBetween={20}
                    navigationElement={navElement}
                    className={`${className}`}
                    swiperSlideClass={slideClass}
                    sliderList={doctorsList.map((doctor) => {
                        return (
                            <LinkAnchor href={doctor.href}>
                                <ClinicsDoctorCard
                                    className={`w-56 h-29.5 overflow-hidden`}
                                    fullNameWithSalutation={doctor?.fullNameSalutation}
                                    profilePic={doctor?.profilePic}
                                    rating={doctor?.rating}
                                    specializations={Array.isArray(doctor?.specializations) ? doctor?.specializations.join(', ') : doctor?.specializations}
                                    experience={doctor?.experience}
                                    href={doctor.href}
                                    publishedReviewsCount={doctor?.publishedReviewsCount}
                                />
                            </LinkAnchor>
                        );
                    })}
                />
            </div>
        </div>
    ) : null;
};
export default DoctorSmallCardSlider;