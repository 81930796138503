import SkeletonLoader from "atoms/skeleton/SkeletonLoader";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getAllDoctors } from "services/ayurvedic-doctor.service";

import Text from 'atoms/Text';
import Button from "atoms/Button";
import IconRightArrow from "atoms/SvgIcons/IconRightArrow.svg";
import { captureEvent } from 'services/analytics.service';
import homepage_patient from "../../assets/mo_engage/homepage_patient.json"
import { APPOINTMENT_GA_CATEGORY } from "constant/myAppointment";
import MakeAppointmentListing from "../AyurvedicDoctor/MakeAppointmentListing";
import DoctorsSummaryCardV3 from "organisms/DoctorsSummaryCardV3";
import DoctorSmallCardSlider from "organisms/DoctorSmallCardSlider";
import Container from "atoms/Container";



const DoctorsListCore = ({ className, page, setPage, city, limit, doctors = [], isSortBySearch, search, selectedSpecialization, isShowApptForm, isTopDoctor }) => {
    const router = useRouter();
    const [doctorList, setDoctorList] = useState(doctors);
    const [loading, setLoading] = useState(false);
    const [featuredDoctorList, setFeaturedDoctorList] = useState([])
    const [currentLimit, setCurrentLimit] = useState(limit);
    const eventCategory = homepage_patient?.category;
    const eventName = homepage_patient?.entity?.specilality_viewall?.event_name;

    const loadDoctorList = async (search) => {
        setLoading(true);
        const formattedCity = city ? city.replace(", ", "-") : "";
        const response = await getAllDoctors({ page, limit, city: formattedCity, search, isCurated: 1, specialities: selectedSpecialization ? encodeURIComponent(selectedSpecialization) : '', isTopDoctor: isTopDoctor ? 1 : 0 });

        if (response.status) {
            const { rows } = response.entity;
            const newObject = { apptForm: true };

            if (isShowApptForm) {
                const updatedRows = rows.length >= 5
                    ? [...rows.slice(0, 4), newObject, ...rows.slice(4)]
                    : [...rows, newObject];

                setDoctorList(updatedRows);
            } else {
                setDoctorList(rows);
            }
        }
        setLoading(false);
    }

    const loadFeaturedDoctors = async () => {
        const response = await getAllDoctors({ page, limit, search: '', isCurated: 1, specialities: selectedSpecialization ? encodeURIComponent(selectedSpecialization) : '', isFeaturedDoctors: 1 });

        if (response.status) {
            const { rows } = response.entity;
            setFeaturedDoctorList(rows);
        }

    }

    useEffect(() => {
        const loadDoctors = async () => {
            let keyword = "";
            if (isSortBySearch) {
                keyword = search;
            } else if (router.query.search) {
                keyword = router.query.search;
            }
            await loadDoctorList(keyword || search);
            await loadFeaturedDoctors();

        };
        loadDoctors();
    }, [isSortBySearch, router.query.search, page, selectedSpecialization, currentLimit]);

    const handleViewMoreProfiles = () => {    
        if(isTopDoctor)   {
            router.push(`/ayurveda/doctors?topDoctors=1`)
        }
        else{
            router.push(`/ayurveda/doctors?specs=${encodeURIComponent(selectedSpecialization)}`)
        }
        captureEvent(eventCategory, eventName, selectedSpecialization, {});
    };
    const handleViewMoreFeaturedDoctors = () => {
        router.push(`/ayurveda/doctors?featured=1`)
    };

    return (
        <>
            <div className={`${className} bg-gray-100 border-t-[1px] border-gray-300`}>
                <div className="container--static pt-2 bg-gray-100  ">
                    {
                        featuredDoctorList.length > 0 ?
                            <DoctorSmallCardSlider
                                className="mb-3"
                                doctorsList={featuredDoctorList}
                                isAyurvedaPage={false}
                                heading={"Featured Doctors"}
                                handleViewMoreFeaturedDoctors={handleViewMoreFeaturedDoctors}
                            />
                            :
                            <SkeletonLoader length={1} type="doctorSmallCardsHorizontalScroller" />
                    }
                </div>
                <div className="bg-basic-white">
                    <div className="container--static pt-2 bg-basic-whitePure">
                        <Container type="fullWidth" className="relative bg-basic-white">
                            {loading ? (
                                <SkeletonLoader length={limit} type="doctorsSummeryCardV2" />
                            ) : doctorList.length === 0 ? (
                                <div className="text-center py-6">
                                    <Text variant="h6" className="font-semibold">No doctors available for the selected specialization.</Text>
                                </div>
                            ) : (
                                <>
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 justify-between">
                                        {doctorList.map((doctor, index) => (
                                            <>
                                                {doctor?.apptForm ?
                                                    <MakeAppointmentListing section={"leadForm"} appointmentType={1} isHomePage={true} /> :
                                                    <div key={doctor?.id} className="group">
                                                        <DoctorsSummaryCardV3
                                                            key={index}
                                                            city={doctor.city}
                                                            degree={doctor.qualifications}
                                                            experience={doctor.experience}
                                                            fullName={doctor.fullNameSalutation}
                                                            image={`${doctor.profilePic ? process.env.NEXT_PUBLIC_PHP_BUCKET_URL + doctor.profilePic : ''}`}
                                                            specializations={doctor.specializations}
                                                            linkHref={doctor?.href}
                                                            consultationFee={doctor?.consultationFee}
                                                            isShowVideoIcon={true}
                                                            rating={doctor?.rating}
                                                            nextAvailableSlots={doctor?.nextAvailableSlots}
                                                            isCurated={true}
                                                            pageName={APPOINTMENT_GA_CATEGORY.INDIVIDUAL_HOMEPAGE}
                                                            doctorId={doctor?.id}
                                                            availableInClinic={doctor?.availableInClinic || 0}
                                                            clinicId={doctor?.clinics?.[0]?.clinicId}
                                                            doctor={doctor}
                                                            clinicFee={doctor?.clinics?.[0]?.consultationFee || 0}
                                                            isConsultationAllowed={doctor.isConsultationAllowed}
                                                            profileViews={doctor?.views}
                                                            preferredLanguages={doctor?.preferredLanguages}
                                                            recentlyConsulted={doctor?.recentlyConsulted}
                                                            publishedReviewsCount={doctor?.publishedReviewsCount}
                                                        />
                                                    </div>}
                                            </>
                                        ))}
                                    </div>
                                </>
                            )}

                        </Container>
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    <Button
                        variant="Link"
                        onClick={handleViewMoreProfiles} dataAutomation='blog-section-homepage' className='text-primary1-500 hover:underline font-semibold text-12-16 mx-auto flex items-center'>
                        <span className='whitespace-nowrap'>View All</span> <IconRightArrow className='inline-block fill-none ml-1 h-3 w-3' />
                    </Button>
                </div>
            </div >
        </>
    );
};

export default DoctorsListCore;
